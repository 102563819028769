#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .m496us90yef-editor_css {
  width: 380px;
  top: 50%;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .m497bebsb6s-editor_css {
  width: 380px;
}
#Banner0_0 .banner0-text-wrapper > .m497jx816zk-editor_css {
}
#Content1_0 .ant-row > .ant-col > .m4an2620k9n-editor_css {
  margin: 40px 0 0.5em;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .bg {
  transition: all 2s ease;
}

#Content0_0 .ant-col > .content0-block-item > .m4c6keb4r2j-editor_css:hover {
  width: 120px;
  height: 120px;
}
#Content0_0 .ant-col > .content0-block-item > .m4c6lkv1y1g-editor_css:hover {
  width: 120px;
  height: 120px;
}
#Content0_0 .ant-col > .content0-block-item > .m4c6lf7nrod-editor_css {
}
#Content0_0 .ant-col > .content0-block-item > .m4c6lf7nrod-editor_css:hover {
  width: 120px;
  height: 120px;
}
#Feature3_0 .ant-row > .ant-col > .m4c6munynsi-editor_css:hover {
}

#Banner0_0 .banner0-text-wrapper > .m497gemgduo-editor_css {
  font-size: 70px;
  width: 800px;
  left: 0;
}

@media screen and (max-width: 767px) {
  #Banner0_0 .banner0-text-wrapper > .m497gemgduo-editor_css {
    font-size: 8vw;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #Content5_0 .home-page > div > .m4gpru2ymgf-editor_css {
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  #Footer2_0.m4gldp37glp-editor_css {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#Banner0_0 .m497kdnbjti-editor_css {
  width: 800px;
  top: 30%;
  padding: 0% 0px 0px;
}

@media screen and (max-width: 767px) {
  #Banner0_0 .m497kdnbjti-editor_css {
    width: 100%;
    position: relative;
    top: 30%;
    padding: 15% 0px 0px;
  }
}
#Banner1_0 .banner-anim > .banner-anim-elem > .m496y649hhc-editor_css {
  top: 60%;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Banner1_0 .banner-anim > .banner-anim-elem > .m4979qu8hp9-editor_css {
  top: 60%;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Banner1_0 .banner-anim > .banner-anim-elem > .m49738dk9x8-editor_css {
  top: 60%;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Nav0_0 .m4gq8fnh1la-editor_css {
  width: 100%;
}
#Content5_0 .ant-col > .content5-block-content > .m49rixc9wpo-editor_css {
  padding: 22% 22% 22% 18%;
}

@media screen and (max-width: 767px) {
  #Content5_0 .ant-col > .content5-block-content > .m49rixc9wpo-editor_css {
    padding: 16% 16% 16% 12%;
  }
}

@media screen and (max-width: 767px) {
  #Content5_0 div > .ant-row > .m4l4yezecr-editor_css {
    margin: 0 0 24px;
  }
}

@media screen and (max-width: 767px) {
  #Content5_0 .m4gposdnrid-editor_css {
    height: 100em;
    margin: 0px 0px 20px;
  }
}

@media screen and (max-width: 767px) {
  #Content9_0 .m4gko9t8woq-editor_css {
    margin: 0px 0px;
    padding: 64px 24px;
  }
}

@media screen and (max-width: 767px) {
  #Content5_0.m4l56ppeei-editor_css {
    height: 2100px;
  }
}
#Content5_0 .ant-col > .content5-block-content > .m49rjrf85a6-editor_css {
  padding: 10%;
}

@media screen and (max-width: 767px) {
  #Content5_0 .ant-col > .content5-block-content > .m49rjrf85a6-editor_css {
    padding: 15% 15% 15% 10%;
  }
}
#Content5_0 .ant-col > .content5-block-content > .m4t963iav8-editor_css {
  padding: 10% 10% 10% 5%;
}
#Content5_0 .ant-col > .content5-block-content > .m49rjezg8yf-editor_css {
  padding: 15% 15% 15% 2%;
}

@media screen and (max-width: 767px) {
  #Content5_0 .ant-col > .content5-block-content > .m49rjezg8yf-editor_css {
    padding: 15%;
  }
}
#Content5_0 .ant-col > .content5-block-content > .m49rast2o-editor_css {
  border-left-width: 20%;
  padding: 15% 15% 15% 5%;
}

@media screen and (max-width: 767px) {
  #Content5_0 .ant-col > .content5-block-content > .m49rast2o-editor_css {
    padding: 15%;
  }
}
#Content5_0 .ant-col > .content5-block-content > .m4tbdc57qto-editor_css {
  padding: 18% 18% 18% 10%;
}
#Content5_0 .ant-col > .content5-block-content > .m4b46rih6z8-editor_css {
  padding: 24% 24% 24% 8%;
}

@media screen and (max-width: 767px) {
  #Content5_0 .ant-col > .content5-block-content > .m4b46rih6z8-editor_css {
    padding: 20% 20% 20% 15%;
  }
}
#Banner1_0 .banner-anim > .banner-anim-elem > .m496qubxmk8-editor_css {
  background-image: url(https://jhxny.com/pic/ban04.webp);
  transition: 2s;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .m496ubrin1-editor_css {
  background-image: url(https://jhxny.com/pic/ban02.webp);
  transition: 2s;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .m4965xde7i-editor_css {
  background-image: url(https://jhxny.com/pic/ban01.webp);
  transition: 2s;
}
#Contact0_0.m4an6atuku7-editor_css {
  background-image: url(https://jhxny.com/pic/ditu1.webp);
  background-clip: border-box;
}

@media screen and (max-width: 767px) {
  #Footer2_0 .home-page > .m4gpulnetfn-editor_css {
    justify-content: center;
    position: sticky;
  }
}

@media screen and (max-width: 767px) {
  #Footer2_0 .home-page > .m4tgtqymnjn-editor_css {
    position: relative;
    clear: right;
  }
}

@media screen and (max-width: 767px) {
  #Footer2_0 .m4gkj76d8et-editor_css {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
